import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getCID(context, cid) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("cid/access/obterCID", {CID: cid}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getCIDBox(context, filtros) {
        filtros.componente = "SearchBoxCID";
        // filtros.locale = this.$i18n.locale;
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("cid/access/listaBox", filtros).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarCondutasCID(context, idCID) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("cid/access/listarCondutasCID", {ID_CID: idCID}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    listarCIDsAtendimento(context, idAtendimento) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("cid/access/listarCIDsAtendimento", {ID_ATENDIMENTO: idAtendimento}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};