import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {
    fgSistema: JwtService.getSistema(),
    fgZoomScreen: JwtService.criarObterGenerico("fgZoomScreen", 100),
    fgTema: JwtService.criarObterGenerico("fgTema", "light"),
    fgMenuLateralCollapse: JwtService.criarObterGenerico("fgMenuLateralCollapse", false)
};

const getters = {
    fgSistema(state) {
        return state.fgSistema;
    },
    fgZoomScreen(state) {
        return state.fgZoomScreen;
    },
    fgTema(state) {
        return state.fgTema;
    },
    fgMenuLateralCollapse(state) {
        return state.fgMenuLateralCollapse;
    }
};

const mutations = {
    updatefgZoomScreen(store, zoom) {
        store.fgZoomScreen = zoom;
        JwtService.salvarGenerico("fgZoomScreen", zoom);
    },
    updatefgTema(store, tema) {
        store.fgTema = tema;
        JwtService.salvarGenerico("fgTema", tema);
    },
    updatefgMenuLateralCollapse(store, menuLateralCollapse) {
        store.fgMenuLateralCollapse = menuLateralCollapse;
        JwtService.salvarGenerico("fgMenuLateralCollapse", menuLateralCollapse);
    }
};

const actions = {
    getConfiguracaoMaster(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("configuracoes/access/buscarConfiguracaoMaster", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    getConfiguracoesMasterMultiplas(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("configuracoes/access/buscarConfiguracoesMasterMultiplas", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    getConfiguracoesGerais() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("configuracoes/access/buscarConfiguracoesGerais").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    getConfiguracoesGeraisUsuario() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("configuracoes/access/buscarConfiguracoesGeraisUsuario").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    salvarConfiguracoesGerais(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("configuracoes/access/salvarConfiguracoesGerais", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    salvarConfiguracoesGeraisUsuario(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("configuracoes/access/salvarConfiguracoesGeraisUsuario", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    updatefgZoomScreen({ commit }, zoom) {
        commit('updatefgZoomScreen', zoom);
    }, 
    updatefgTema({ commit }, tema) {
        commit('updatefgTema', tema);
    }, 
    updatefgMenuLateralCollapse({ commit }, menuLateralCollapse) {
        commit('updatefgMenuLateralCollapse', menuLateralCollapse);
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};