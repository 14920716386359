import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
	getPortadoresBox(context, dados) {
		return new Promise(resolve => {
			if (JwtService.getToken()) {
					ApiService.setHeader();
					ApiService.post("portadores/access/listaBox", dados).then(({ data }) => {
							resolve(data);
					});
				}
		});
	},
	getPortadoresPorTipoInstituicaoConta(context, dados){
		return new Promise(resolve => {
			if (JwtService.getToken()) {
					ApiService.setHeader();
					ApiService.post("portadores/access/listarPorTipoInstituicaoContaPaciente", dados).then(({ data }) => {
							resolve(data);
					});
				}
		});
	},
	getPortadoresBandeiras(context, dados){
		return new Promise(resolve => {
			if (JwtService.getToken()) {
					ApiService.setHeader();
					ApiService.post("portadores/access/listaBoxAdquirentebandeiras", dados).then(({ data }) => {
							resolve(data);
					});
				}
		});
	},
	getPortadoresBandeirasTipoPgto(context, dados){
		return new Promise(resolve => {
			if (JwtService.getToken()) {
					ApiService.setHeader();
					ApiService.post("portadores/access/listaBoxAdquirentebandeirasTpPgto", dados).then(({ data }) => {
							resolve(data);
					});
				}
		});
	},
	getPortadoresTaxa(context, dados){
		return new Promise(resolve => {
			if (JwtService.getToken()) {
					ApiService.setHeader();
					ApiService.post("portadores/access/obterPortadorTaxa", dados).then(({ data }) => {
							resolve(data);
					});
				}
		});
	}
};

export default {
    state,
    actions,
    mutations,
    getters
};