<template>
  <ModalBase ref="modalUsuariosOnline">
    <div class="text-center mb-13">
      <h1 class="mb-3">Usuários Online</h1>
      <div class="text-muted fw-semibold fs-5">Colaboradores online dentro da plataforma no momento</div>
    </div>
    <div id="kt_modal_users_search_handler" data-kt-search-keypress="true" data-kt-search-min-length="2" data-kt-search-enter="enter" data-kt-search-layout="inline">
      <div class="py-5">
        <div v-if="appUsuariosOnline.length" data-kt-search-element="suggestions">
          <div class="mh-375px scroll-y me-n7 pe-7">
            <a 
              v-for="usuarioOnline in appUsuariosOnline" 
              :key="usuarioOnline.socketId" 
              href="javascript:;" 
              class="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1"
            >
              <div class="symbol symbol-35px symbol-circle me-5">
                <img v-if="usuarioOnline.avatar" alt="Pic" src="/assets/media/avatars/300-6.jpg" />
                <span v-if="!usuarioOnline.avatar" class="symbol-label bg-light-info text-info fw-semibold">
                  {{ (usuarioOnline.usuario) ?  usuarioOnline.usuario.charAt(0) : "" }}
                </span>
              </div>
              <div class="fw-semibold">
                <span class="fs-6 text-gray-800 me-2">
                  {{ usuarioOnline.usuario }} ({{ usuarioOnline.email }})
                </span>
                <span v-if="((usuarioOnline.perfis) && (usuarioOnline.perfis.length))" class="badge badge-light">
                  {{ usuarioOnline.perfis[0].PERFIL_DESCRICAO }}
                </span>
              </div>
            </a>
          </div>
        </div>
        <div v-if="!appUsuariosOnline.length" data-kt-search-element="empty" class="text-center d-none">
          <div class="fw-semibold py-10">
            <div class="text-gray-600 fs-3 mb-2">Sem usuários encontrados</div>
            <div class="text-muted fs-6">Ainda não foram encontrados usuários conectados a plataforma...</div>
          </div>
          <div class="text-center px-5">
            <img src="/assets/media/illustrations/sketchy-1/1.png" alt="" class="w-100 h-200px h-sm-325px" />
          </div>
        </div>
      </div>
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from "@/components/modals/ModalBase.vue";
import { mapGetters } from 'vuex';

export default {
  name: "UsuariosOnline", 
  components: {
    ModalBase
  }, 
  computed: {
    ...mapGetters(["appUsuariosOnline"]), 
  }, 
  methods: {
    toggleModal() {
      this.$refs.modalUsuariosOnline.toggleModal();
    }
  }
}
</script>

<style>

</style>