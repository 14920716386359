<template>
  <div class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <ASideLeft :updateExists="updateExists" @refreshApp="refreshApp" />
      <div 
        class="wrapper d-flex flex-column flex-row-fluid" 
        id="kt_wrapper" 
        :style="{ 'padding-top': appModoTotem ? '10px' : '' }"
      >
        <HeaderTop />
        <ContentMain />
        <FooterBottom />
      </div>
    </div>
  </div>
  <ActivitiesDrawer />
  <ChatDrawer />
  <DemosDrawer />
  <HelpDrawer />
  <ScrollTop />
  <InviteFriends />
  <UsersSearch />
  <UsuariosOnline ref="modalUsuariosOnline" />
  <ModalBase ref="idleTimeoutModal" :fullscreen="true">
    <img 
      src="/assets/media/illustrations/dozzy-1/17.png" 
      style="margin: 0 auto; display: block; height: 420px; margin-bottom: 20px;"
    />
    <h1 style="text-align: center;">
      <span class="indicator-label">
        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        Você está inativo por {{ periodoInatividade }} minutos. Você será desconectado em {{ segundosLogout }} segundos.
      </span>
    </h1>
  </ModalBase>
</template>

<script>
import { markRaw } from "vue";
import JwtService from "@/core/services/jwt.service";

import ASideLeft from "@/components/ASideLeft";
import HeaderTop from "@/components/HeaderTop";
import ContentMain from "@/components/ContentMain";
import FooterBottom from "@/components/FooterBottom";

import DemosDrawer from "@/components/DemosDrawer";
import ChatDrawer from "@/components/ChatDrawer";
import ActivitiesDrawer from "@/components/ActivitiesDrawer";
import HelpDrawer from "@/components/HelpDrawer";
// import EngageToolbar from "@/components/EngageToolbar";
import ScrollTop from "@/components/ScrollTop";
import InviteFriends from "@/components/modals/InviteFriends";
import UsersSearch from "@/components/modals/UsersSearch";
import UsuariosOnline from "@/components/modals/UsuariosOnline";

import { mapGetters, mapActions } from 'vuex';

import { useToast } from "vue-toastification";

import { useIdle } from "@vueuse/core";

import ModalBase from '@/components/modals/ModalBase.vue';

export default {
  name: 'App',
  components: {
    ASideLeft,
    HeaderTop,
    ContentMain,
    FooterBottom,
    DemosDrawer,
    ChatDrawer,
    ActivitiesDrawer,
    HelpDrawer,
    // EngageToolbar,
    ScrollTop,
    InviteFriends,
    UsersSearch,
    UsuariosOnline,
    ModalBase
  },
  data() {
    return {
      firstConnect: true, 
      periodoInatividade: 60, 
      intervalIdle: null, 
      timeoutLogout: null, 
      idleObject: {}, 
      segundosLogout: 10, 
      refreshing: false, 
      registration: null, 
      updateExists: false
    };
  },
  sockets: {
    connect() {
      this.updateAppOnline(true);

      if (this.firstConnect) {
        this.firstConnect = false;
      } else {
        if(this.avisaOnlineOffline) {
          const toast = useToast();

          toast.success("Você foi reconectado a internet!", {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
        }
      }
    },
    disconnect() {
      this.updateAppOnline(false);

      if(this.avisaOnlineOffline) {
        const toast = useToast();

        toast.error("Você foi desconectado da internet!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }
    },
    async logout() {
      await this.$swal({
        icon: "warning",
        text: "Seu usuário foi conectado em outra instância, você será deslogado nesta aba.",
        timer: 3000,
        timerProgressBar: true
      });

      window.onbeforeunload = null;
      this.logout();
    }
  },
  created() {
    document.addEventListener(
      "swUpdated", this.showRefreshUI, { once: true }
    );
    document.addEventListener(
      "swCached", this.showRefreshUICached, { once: true }
    );
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener(
        "controllerchange", () => {
          if (this.refreshing) return;
          this.refreshing = true;
          window.location.reload();
        }
      );
    }
  },
  async mounted() {
    let validacao = await this.getValidateToken();

    if (!validacao.resultado) {
      this.logout();
    }

    this.getStaffMedicoUsuarioGlobal();

    if (JwtService.getSistema()) {
      /*document.title = JwtService.getSistema().charAt(0).toUpperCase() + JwtService.getSistema().slice(1).toLowerCase() + " Spirit";
      if((document.getElementById("favicon")) && (document.getElementById("favicon").href)) {
        document.getElementById("favicon").href = "/assets/custom/logos/" + JwtService.getSistema().toLowerCase() + "-favicon.ico";
      }*/
    }

    await this.$nextTick();
    
    await this.getAvatar(this.idEmitenteMLUsuario);

    let periodoMaximoInatividade = await this.getConfiguracaoMaster({
      CONTEUDO_NOME: "CONTEUDO",
      NIVEL: "1",
      OPERACAO: "C",
      USUARIO: "GERAL",
      SISTEMA: "GERAL",
      MODULO: "LOGIN",
      CAMPO: "PERIODO_MAXIMO_INATIVIDADE",
      CONTEUDO: ""
    });

    if (periodoMaximoInatividade.CONTEUDO) {
      this.periodoInatividade = parseInt(periodoMaximoInatividade.CONTEUDO);
    }

    this.emitter.on("acessarUsuariosOnline", () => {
      this.$refs.modalUsuariosOnline.toggleModal();
    });
    this.$socket.emit("usuariosOnline", {});

    this.idleObject = useIdle(this.periodoInatividade * 60 * 1000);

    this.intervalIdle = setInterval(() => {
      if ((this.$route.name != "chamadaNaTV") && (this.$route.name != "totemPaciente")) {
        if (this.idleObject.idle) {
          if ((this.$refs.idleTimeoutModal) && (!this.$refs.idleTimeoutModal.showModal)) {
            this.segundosLogout = 10;
            this.$refs.idleTimeoutModal.toggleModal();
            this.timeoutLogout = setTimeout(() => {
              window.onbeforeunload = null;
              this.logout();
            }, 10000);
          } else if (this.segundosLogout > 0) {
            this.segundosLogout--;
          }
        } else {
          if ((this.$refs.idleTimeoutModal) && (this.$refs.idleTimeoutModal.showModal)) {
            this.$refs.idleTimeoutModal.toggleModal();
            clearTimeout(this.timeoutLogout);
          }
        }
      }
    }, 1000);
  },
  computed: {
    ...mapGetters([
      "idEmitenteMLUsuario", 
      "appModoTotem", 
      "avisaOnlineOffline"
    ])
  },
  methods: {
    ...mapActions([
      "getValidateToken", 
      "logout", 
      "updateAppOnline", 
      "getAvatar", 
      "getConfiguracaoMaster", 
      "sleepTime", 
      "getStaffMedicoUsuarioGlobal"
    ]),
    showRefreshUI(e) {
      this.registration = markRaw(e.detail);
      this.updateExists = true;
    },
    showRefreshUICached() {
      this.updateExists = true;
    },
    async refreshApp() {
      this.updateExists = false;

      console.log("Atualizando...");

      if ((this.registration) && (this.registration.waiting)) {
        this.registration.waiting.postMessage("skipWaiting");
      }
      await this.sleepTime(3000);

      window.location.reload(true);
    }
  }
}
</script>

<style>
.content {
  padding-bottom: 100px;
}

label.fs-6.fw-semibold.form-label.mt-3 {
  margin-top: 0 !important;
}

#confetti-canvas {
  z-index: 1003;
}

.tempus-dominus-widget {
  margin: 0 auto;
}

.swal2-container {
  z-index: 100000 !important;
}

.hg-button.hg-standardBtn {
  font-size: 24px;
}

.hg-button.hg-functionBtn.hg-button-bksp {
  font-size: 18px;
}

.container-xxl {
  padding-bottom: 40px;
}

.tempus-dominus-widget.light .date-container-days div.day.active {
  background-color: inherit;
  border: 3px solid var(--bs-primary) !important;
  color: var(--bs-gray-900);
  text-shadow: none;
}

.tempus-dominus-widget.light .date-container-days div.day.active::before {
  display: none;
}

#pepAnchorPoint {
  margin: 0px 0px !important;
}

svg>g>g.google-visualization-tooltip {
  pointer-events: none
}

.symbol-group .symbol {
  margin-left: -8px;
}

.animate__animated {
  -webkit-animation-fill-mode: none !important;
  animation-fill-mode: none !important;
}

.cke_notifications_area {
  display: none !important;
}

.bg-medicamento {
  background-color: #66afb3 !important;
}

.bg-receituario {
  background-color: #66afb3 !important;
}

.bg-consulta {
  background-color: #8100f7 !important;
}

.bg-exame {
  background-color: #00bef7 !important;
}

.bg-cirurgia {
  background-color: #f77d00 !important;
}

.bg-solicitacao {
  background-color: #004ef7 !important;
}

.bg-agenda-retorno {
  background-color: #a900f7 !important;
}

@media print {
  body {
    background-color: white !important;
  }

  #kt_wrapper {
    padding-top: 0 !important;
  }

  #kt_aside {
    display: none !important;
  }

  #kt_header {
    display: none !important;
  }

  #kt_footer {
    display: none !important;
  }

  .scrolltop-btn {
    display: none !important;
  }

  #kt_content {
    padding: 0 !important;
  }

  #container-pep {
    padding: 0 !important;
  }

  .container-atendimento-2 {
    display: block !important;
  }

  .card-lateral-pep {
    border-radius: 0 !important;
  }

  .card-lateral-pep-toolbar {
    display: none !important;
  }

  .pep-botoes-atendimento {
    display: none !important;
  }

  .pep-aviso-mensagem {
    display: none !important;
  }

  .container-atendimento-2-graficos {
    display: none !important;
  }

  .filtros-timeline-pep {
    display: none !important;
  }

  .cabecalho-impressao-pep {
    display: block !important;
  }

  #menuPEPLateral {
    display: none !important;
  }

  .card-historico-atendimento {
    padding: 0 !important;
    border-radius: 0 !important;
  }

  #pepAnchorPoint {
    margin: 0 !important;
  }

  .card-timeline-header {
    padding: 0 0.5rem !important;
  }

  .card-timeline-header .card-toolbar {
    display: none !important;
  }

  .card-timeline-body {
    padding: 1rem 0.5rem !important;
  }

  .btn-imprimir-pep {
    display: none !important;
  }

  .accordion-icon {
    display: none !important;
  }

  .btn-excluir-historico {
    display: none !important;
  }

  .btn-close-historico {
    display: none !important;
  }
}
</style>
