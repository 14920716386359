import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getServicos(context, dados) {
        if((!dados.pagina) || (!dados.quantidadePorPagina)) {
            dados.pagina = 1;
            dados.quantidadePorPagina = 25;
        }
        dados.tipo = 'S';
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/listar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getServico(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/obter", {ID_PRODUTO: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getServicoSimples(context, idServico) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/obterServicoSimples", {ID_PRODUTO: idServico}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getProcedimentosLaudo() {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("produtos/access/listaLocal").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getServicosBox(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/listaBox", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getServicosBoxTipoC(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/listaBoxTipoC", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getServicosBoxTipoCS(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/listaBoxTipoCS", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getServicosBoxTipoS(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/listaBoxTipoS", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getServicosBoxTipoP(context, dados) {
        return new Promise((resolve) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/listaBoxTipoP", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    deleteServico(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/excluir", {ID_PRODUTO: dados.id, componente: dados.componente}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    postServico(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/salvar", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getProdutosKit(context, idProduto) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/listarProdutosKit", {ID_PRODUTO:idProduto}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getProdutosPrecos(context, idProduto) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("servicos/access/listarProdutosPrecos", {ID_PRODUTO:idProduto}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};