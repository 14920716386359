<template>
  <div v-if="menu.visible" class="menu-item" :class="{'menu-accordion': ((menu.submenu) && (menu.submenu.length))}" :data-kt-menu-trigger="(((menu.submenu) && (menu.submenu.length)) ? 'click' : '')">
    <a v-if="!((menu.submenu) && (menu.submenu.length))" class="menu-link" :class="{'active': $route.name == menu.name}" href="javascript:;" @click="acessarMenu">
      <span class="menu-icon" v-if="!bullet">
        <span class="svg-icon svg-icon-2">
          <slot name="icon"></slot>
        </span>
      </span>
      <span class="menu-bullet" v-if="bullet">
        <span class="bullet bullet-dot"></span>
      </span>
      <span v-if="!menu.bold" class="menu-title">
        {{ $t(menu.title) }}
      </span>
      <span v-if="menu.bold" class="menu-title">
        <b>{{ $t(menu.title) }}</b>
      </span>
    </a>
    <span class="menu-link" v-if="((menu.submenu) && (menu.submenu.length))">
      <span class="menu-icon" v-if="!bullet">
        <span class="svg-icon svg-icon-2">
          <slot name="icon"></slot>
        </span>
      </span>
      <span class="menu-bullet" v-if="bullet">
        <span class="bullet bullet-dot"></span>
      </span>
      <span v-if="!menu.bold" class="menu-title">
        {{ $t(menu.title) }}
      </span>
      <span v-if="menu.bold" class="menu-title">
        <b>{{ $t(menu.title) }}</b>
      </span>
      <span class="menu-arrow"></span>
    </span>
    <div class="menu-sub menu-sub-accordion" v-if="((menu.submenu) && (menu.submenu.length))">
      <MenuItem v-for="submenu in menu.submenu" :key="submenu" :menu="submenu" :bullet="true" />
    </div>
    <div v-if="menu.separator" class="separator my-2" style="margin-left: calc(1.5rem + 10px);"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MenuItem",
  props: ["menu", "bullet"],
  methods: {
    ...mapActions(["registrarMenuFavorito"]), 
    async acessarMenu() {
      if(this.menu.acessar) {
        this.registrarMenuFavorito(this.menu.acessar).then(() => {
          this.$root.$emit("atualizarMenuFavoritos", {});
        });
        
        this.$router.push({
          name: this.menu.acessar
        });
      }
    }
  }
}
</script>