import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

const state = {
    usuario: "",
    email: "",
    idEmitenteML: "",
    idEmitenteMLUsuario: "",
    emitenteML: "",
    perfis: [],
    acessaSpirit: false,
    avatar: {},
    logomarca: "", 
    repasseDireto: false, 
    avisaOnlineOffline: true, 
    medicoUsuario: {}
};

const getters = {
    usuario(state) {
        return state.usuario;
    },
    email(state) {
        return state.email;
    },
    idEmitenteML(state) {
        return state.idEmitenteML;
    },
    idEmitenteMLUsuario(state) {
        return state.idEmitenteMLUsuario;
    },
    emitenteML(state) {
        return state.emitenteML;
    },
    perfis(state) {
        return state.perfis;
    },
    acessaSpirit(state) {
        return state.acessaSpirit;
    },
    avatar(state) {
        return state.avatar;
    },
    logomarca(state) {
        return state.logomarca;
    },
    repasseDireto(state) {
        return state.repasseDireto;
    },
    avisaOnlineOffline(state) {
        return state.avisaOnlineOffline;
    },
    medicoUsuario(state) {
        return state.medicoUsuario;
    }
};

const mutations = {
    updateUserInfo(store, userInfo) {
        if (userInfo.idEmitenteML) {
            store.idEmitenteML = userInfo.idEmitenteML;
        }

        if (userInfo.idEmitenteMLUsuario) {
            store.idEmitenteMLUsuario = userInfo.idEmitenteMLUsuario;
        }

        if (userInfo.usuario) {
            store.usuario = userInfo.usuario;
        }

        if (userInfo.email) {
            store.email = userInfo.email;
        }

        if (userInfo.nome) {
            store.emitenteML = userInfo.nome;
        }

        if ((userInfo.perfis) && (userInfo.perfis.length)) {
            store.perfis = userInfo.perfis;
        }

        if ((userInfo.acessaSpirit) && (userInfo.acessaSpirit == 1)) {
            store.acessaSpirit = true;
        }

        if (userInfo.logomarca) {
            store.logomarca = userInfo.logomarca;
        }

        if ((userInfo.repasseDireto) && (userInfo.repasseDireto == 1)) {
            store.repasseDireto = true;
        }
    },
    updateAvatar(store, avatar) {
        store.avatar = avatar;
    },
    updateAvisaOnlineOffline(store, avisaOnlineOffline) {
        store.avisaOnlineOffline = avisaOnlineOffline;
    }, 
    updateMedicoUsuario(store, medicoUsuario) {
        console.log(medicoUsuario);
        store.medicoUsuario = medicoUsuario;
    }
};

const actions = {
    atualizarAvisaOnlineOffline({ commit }, avisaOnlineOffline) {
        commit("updateAvisaOnlineOffline", avisaOnlineOffline);
    }, 
    getValidateToken({ commit }) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("auth/verify").then(({ data }) => {
                    commit('updateUserInfo', data);
                    if (data.token) {
                        JwtService.saveToken(data.token);
                    }
                    resolve(data);
                });
            }
        });
    },
    getUsuarios(context, dados) {
        if ((!dados.pagina) || (!dados.quantidadePorPagina)) {
            dados.pagina = 1;
            dados.quantidadePorPagina = 25;
        }
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("usuarios/access/listarNuvem", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getUsuariosTodos() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("usuarios/access/listarNuvemTodos").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getUsuario(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("usuarios/access/obterNuvem", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getUsuariosLocal() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("usuarios/access/listarLocal").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    postUsuario(context, usuario) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("usuarios/access/salvar", usuario).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getAvatar({ commit }, idEmitenteMLUsuario) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("usuarios/access/obterAvatar", {id: idEmitenteMLUsuario}).then(({ data }) => {
                    commit('updateAvatar', data);
                    resolve(data);
                });
            }
        });
    },
    postAvatar({ commit }, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("usuarios/access/salvarAvatar", dados).then(({ data }) => {
                    commit('updateAvatar', data);
                    resolve(data);
                });
            }
        });
    },
    salvarNovaSenha(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("usuarios/access/salvarNovaSenha", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    validarSenha(context, senha) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("usuarios/access/validarSenha", {SENHA: senha}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    logout() {
        JwtService.destroyToken();
        window.location.href = "https://auth.micrologos.com.br/#/login/" + JwtService.getSistema();
        // window.location.href = "https://auth.clinicspirit.com/#/login/" + JwtService.getSistema();
    },
    recuperarSenha(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("auth/recuperarSenha", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    gerarNovaSenha(context, dados) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("usuarios/access/gerarNovaSenha", dados).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    aceitarCobrancaTeleatendimento() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("usuarios/access/aceitarCobrancaTeleatendimento").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    registrarMenuFavorito(context, acesso) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.post("usuarios/access/registrarMenuFavorito", {ACESSO: acesso}).then(({ data }) => {
                    resolve(data);
                });
            }
        });
    }, 
    listarMenusFavoritos() {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("usuarios/access/listarMenusFavoritos").then(({ data }) => {
                    resolve(data);
                });
            }
        });
    },
    getStaffMedicoUsuarioGlobal({ commit }) {
        return new Promise(resolve => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("staffMedicos/access/obterMedicoUsuario").then(({ data }) => {
                    commit("updateMedicoUsuario", data);
                    resolve(data);
                });
            }
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};