import axios from "axios";
import JwtService from "@/core/services/jwt.service";
// import { get } from "@vueuse/core";

const location = "BR";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
        // axios.defaults.baseURL = "http://localhost/backend-spirit/";

        if(location == "US") {
            axios.defaults.baseURL = "https://backend.clinicspirit.com/";
        } else {
            axios.defaults.baseURL = "https://backend.micrologos.com.br/";
        }
    },

    getBackendUrl() {
        return axios.defaults.baseURL;
    },

    getClinicUrl() {
        if(location == "US") {
            return "https://clinicspirit.com/";
        } else {
            return "https://clinic.micrologos.com.br/";
        }
    },

    getChatUrl() {
        if(location == "US") {
            return "https://chat.clinicspirit.com/";
        } else {
            return "https://chat.micrologos.com.br/";
        }
    },

    setHeader() {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Token ${JwtService.getToken()}`;
    },

    query(resource, params) {
        return axios.get(resource, params).catch(error => {
            // console.log(error);
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    get(resource, slug = "") {
        return axios.get(`${resource}/${slug}`).catch(error => {
            // console.log(error);
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    getFile(resource, slug = "") {
        return axios({
            url: resource + "/" + slug,
            method: "GET",
            responseType: "blob"
        }).catch(error => {
            // console.log(error);
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        return axios.post(`${resource}`, params);
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        return axios.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return axios.delete(resource).catch(error => {
            // console.log(error);
            throw new Error(`[RWV] ApiService ${error}`);
        });
    }
};

export default ApiService;