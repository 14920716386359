<template>
  <div id="kt_aside" class="aside" data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
    <!--begin::Aside Toolbarl-->
    <div class="aside-toolbar flex-column-auto" id="kt_aside_toolbar">
      <!--begin::Aside user-->
      <!--begin::User-->
      <div v-if="updateExists" class="d-flex d-lg-block flex-column bg-dark text-gray-300 text-center rounded-0 cookiealert py-5 show">
        Uma nova versão do nosso App está disponível<br />
        <button 
          type="button" 
          class="btn btn-primary btn-sm" 
          style="margin-top: 10px;" 
          @click="$emit('refreshApp')"
        >
          Atualizar Agora
        </button>
      </div>
      <div class="aside-user d-flex align-items-sm-center justify-content-center py-5">
        <!--begin::Symbol-->
        <div class="symbol symbol-50px">
          <img v-if="!avatar.AVATAR" src="/assets/media/svg/files/blank-image.svg" alt="" />
          <InputFoto v-if="avatar.AVATAR" v-model="avatarLocal" :disabled="true" :nolabel="true" :nooutline="true" :small="true" />
        </div>
        <!--end::Symbol-->
        <ASideUserInfo />
      </div>
      <!--end::User-->
      <!--begin::Aside search-->
      <div class="aside-search py-5">
        <!--begin::Search-->
        <div id="kt_header_search" class="header-search d-flex align-items-center w-100" data-kt-search-keypress="true" data-kt-search-min-length="2" data-kt-search-enter="enter" data-kt-search-layout="menu" data-kt-search-responsive="false" data-kt-menu-trigger="auto" data-kt-menu-permanent="true" data-kt-menu-placement="bottom-start">
          <!--begin::Form-->
          <form data-kt-search-element="form" class="w-100 position-relative" autocomplete="off">
            <!--begin::Hidden input(Added to disable form autocomplete)-->
            <input type="hidden" />
            <!--end::Hidden input-->
            <!--begin::Icon-->
            <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
            <span class="svg-icon svg-icon-2 search-icon position-absolute top-50 translate-middle-y ms-4">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
              </svg>
            </span>
            <!--end::Svg Icon-->
            <!--end::Icon-->
            <!--begin::Input-->
            <input type="password" class="search-input form-control ps-13 fs-7 h-40px" name="search" :placeholder="$t('Buscar Menus')" v-model="buscaMenus" @input="onSearch" />
            <!--end::Input-->
            <!--begin::Spinner-->
            <span class="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5" data-kt-search-element="spinner">
              <span class="spinner-border h-15px w-15px align-middle text-gray-400"></span>
            </span>
            <!--end::Spinner-->
            <!--begin::Reset-->
            <span class="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4" data-kt-search-element="clear">
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
              <span class="svg-icon svg-icon-2 svg-icon-lg-1 me-0">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </span>
            <!--end::Reset-->
          </form>
          <!--end::Form-->
        </div>
        <!--end::Search-->
      </div>
      <!--end::Aside search-->
      <!--end::Aside user-->
    </div>
    <!--end::Aside Toolbarl-->
    <!--begin::Aside menu-->
    <div class="aside-menu flex-column-fluid">
      <!--begin::Aside Menu-->
      <div class="hover-scroll-overlay-y px-2 my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="{default: '#kt_aside_toolbar, #kt_aside_footer', lg: '#kt_header, #kt_aside_toolbar, #kt_aside_footer'}" data-kt-scroll-wrappers="#kt_aside_menu" data-kt-scroll-offset="5px">
        <MenuLeft ref="menuLeft" :buscaMenus="buscaMenus" />
      </div>
      <!--end::Aside Menu-->
    </div>
    <!--end::Aside menu-->
    <!--begin::Footer-->
    <div class="aside-footer flex-column-auto py-5" id="kt_aside_footer">
      <a href="javascript:;" class="btn btn-custom btn-primary w-100" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss-="click" title="Contacte o nosso suporte!">
        <span class="btn-label">Suporte Micrologos</span>
        <!--begin::Svg Icon | path: icons/duotune/general/gen005.svg-->
        <span class="svg-icon btn-icon svg-icon-2">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor" />
            <rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor" />
            <rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor" />
            <rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor" />
            <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
          </svg>
        </span>
        <!--end::Svg Icon-->
      </a>
    </div>
    <!--end::Footer-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ASideUserInfo from "@/components/ASideUserInfo";
import MenuLeft from "@/components/MenuLeft";

import InputFoto from "@/components/inputs/InputFoto.vue";

export default {
  name: "ASideLeft",
  data() {
    return {
      buscaMenus: ""
    }
  }, 
  props: ["updateExists"], 
  components: {
    ASideUserInfo,
    MenuLeft,
    InputFoto
  },
  computed: {
		...mapGetters(["avatar"]),
    avatarLocal: {
      get() {
        if(this.avatar.AVATAR.indexOf("data:image") == -1) {
          return 'data:image/jpeg;base64,' + this.avatar.AVATAR;
        } else {
          return this.avatar.AVATAR;
        }
      },
      set() {
      }
    }
  },
  methods: {
    onSearch() {
      this.$nextTick(() => {
        this.$refs.menuLeft.inicializaMenu();

        this.$nextTick(() => {
          this.$refs.menuLeft.verificaPermissao();
        });
      });
    }
  }
}
</script>

<style scoped>

</style>